<template>
    <div>
        <v-dialog v-model="imgdialog" max-width="450px">
            <v-card tile>
                <v-card-text>
                    <v-container>
                        <v-img
                                contain
                                max-height="150"
                                :src="selectedSrc"
                        ></v-img>
                    </v-container>
                </v-card-text>

                <v-divider class="primary"/>
                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="error" @click="() => {imgdialog=false}">
                        Back
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialog" max-width="450px" persistent>

            <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                    <v-icon dark>
                        mdi-plus
                    </v-icon>
                    Create
                </v-btn>
            </template>


            <v-col class='flex flex-end'>
                <v-icon dark medium class="mr-1" @click="dialog = false">
                    mdi-close
                </v-icon>
            </v-col>


            <v-card tile>
                <v-card-title class="primary white--text">
                    <span class="text-h5"><v-icon large color="white" class="mr-2">{{formIcon}}</v-icon>{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                    <v-container>
                        <v-row no-gutters>
                            <v-col cols="12" class="my-2 d-flex justify-center" v-if="selectedImage">

                                <v-img
                                        contain
                                        max-height="150"
                                        :src="selectedImage"
                                ></v-img>
                            </v-col>
                            <v-col cols="12">
                                <v-file-input
                                        label="Cover"
                                        outlined
                                        dense
                                        @change="getImage"
                                        v-model="editedItem.img_url"
                                        prepend-icon=""
                                        append-icon="mdi-image"
                                ></v-file-input>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                        outlined
                                        dense
                                        v-model="editedItem.name"
                                        :error-messages="nameErrors"
                                        required
                                        @input="editedIndex == -1 ? $v.editedItem.name.$touch(): null"
                                        @blur="editedIndex == -1 ? $v.editedItem.name.$touch(): null"
                                        label="Name"
                                        append-icon="mdi-rename-box"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                        outlined
                                        dense
                                        type="date"
                                        v-model="editedItem.date"
                                        :error-messages="dateErrors"
                                        required
                                        @input="editedIndex == -1 ? $v.editedItem.date.$touch(): null"
                                        @blur="editedIndex == -1 ? $v.editedItem.date.$touch(): null"
                                        label="Date"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                        dense
                                        outlined
                                        v-model="editedItem.category"
                                        label="Category"
                                        :error-messages="categoryErrors"
                                        required
                                        @input="editedIndex == -1 ? $v.editedItem.category.$touch(): null"
                                        @blur="editedIndex == -1 ? $v.editedItem.category.$touch(): null"
                                        append-icon="mdi-shape"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-select
                                        dense
                                        outlined
                                        v-model="editedItem.logo_position"
                                        :items="['Invisible',/*'Top-Left','Top-Right',*/'Bottom-Left','Bottom-Right']"
                                        label="Logo position"
                                        :error-messages="logo_positionErrors"
                                        required
                                        @input="editedIndex == -1 ? $v.editedItem.logo_position.$touch(): null"
                                        @blur="editedIndex == -1 ? $v.editedItem.logo_position.$touch(): null"
                                        append-icon="mdi-arrow-decision"
                                ></v-select>
                            </v-col>
                            <v-col cols="12">
                                <v-select
                                        dense
                                        outlined
                                        v-model="editedItem.is_dp_visible"
                                        :items="['Yes','No']"
                                        label="Profile Visibility"
                                        :error-messages="dp_positionErrors"
                                        required
                                        @input="editedIndex == -1 ? $v.editedItem.dp_position.$touch(): null"
                                        @blur="editedIndex == -1 ? $v.editedItem.dp_position.$touch(): null"
                                        append-icon="mdi-eye"
                                ></v-select>
                            </v-col>
                            <v-col cols="12">
                                <v-select
                                        dense
                                        outlined
                                        v-show="editedItem.is_dp_visible == 'Yes'"
                                        v-model="editedItem.dp_position"
                                        :items="['Bottom-Left','Bottom-Right']"
                                        label="Profile position"
                                        :error-messages="dp_positionErrors"
                                        required
                                        @input="editedIndex == -1 ? $v.editedItem.dp_position.$touch(): null"
                                        @blur="editedIndex == -1 ? $v.editedItem.dp_position.$touch(): null"
                                        append-icon="mdi-arrow-decision"
                                ></v-select>
                            </v-col>
                            <v-col cols="12">
                                <v-select
                                        dense
                                        outlined
                                        v-model="editedItem.status"
                                        :items="['Active','Inactive']"
                                        label="Status"
                                        :error-messages="statusErrors"
                                        required
                                        @input="editedIndex == -1 ? $v.editedItem.status.$touch(): null"
                                        @blur="editedIndex == -1 ? $v.editedItem.status.$touch(): null"
                                        append-icon="mdi-list-status"
                                ></v-select>
                            </v-col>
                            <!-- <v-col cols="12">
                              <v-select
                                dense
                                outlined
                                v-model="editedItem.package_type"
                                :items="['Free', 'Premium']"
                                label="Package Type"
                                :error-messages="package_typeErrors"
                                required
                                @input="editedIndex == -1 ? $v.editedItem.package_type.$touch(): null"
                                @blur="editedIndex == -1 ? $v.editedItem.package_type.$touch(): null"
                                append-icon="mdi-briefcase"
                              ></v-select>
                            </v-col> -->
                            <v-col cols="12">
                                <v-select
                                        dense
                                        outlined
                                        v-model="editedItem.type"
                                        :items="['Business', 'Political']"
                                        label="Type"
                                        :error-messages="typeErrors"
                                        required
                                        @input="editedIndex == -1 ? $v.editedItem.type.$touch(): null"
                                        @blur="editedIndex == -1 ? $v.editedItem.type.$touch(): null"
                                        append-icon="mdi-briefcase"
                                ></v-select>
                            </v-col>
                            <!-- <v-col cols="12">
                              <v-textarea
                                dense
                                outlined
                                v-model="editedItem.description"
                                label="Description"
                                append-icon="mdi-pencil"
                                ></v-textarea>
                          </v-col> -->
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-alert v-if="error" dense type="error">
                                    {{error}}
                                </v-alert>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>

                <v-divider class="primary"/>
                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="error" @click="close">
                        Cancel
                    </v-btn>
                    <v-btn color="success" @click="save">
                        Save
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
                <v-card-title class="error white--text"
                >
                    <v-icon color="white" class="mr-2">mdi-delete</v-icon>
                    You sure you want to delete this item?
                </v-card-title
                >
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" @click="closeDelete"
                    >Cancel
                    </v-btn
                    >
                    <v-btn color="success" @click="deleteItemConfirm"
                    >OK
                    </v-btn
                    >
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-data-table
                :headers="headers"
                :items="data"
                sort-by="calories"
                class="elevation-3"
        >
            <template v-slot:item.img_url="{item}">
                <v-avatar class="profile my-2" color="grey" size="80" tile @click="openImage(item.img_url)">
                    <v-img contain v-if="item.img_url" :src="item.img_url"></v-img>
                </v-avatar>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-icon small class="mr-2 warning--text" @click="editItem(item)">
                    mdi-pencil
                </v-icon>
                <v-icon small @click="deleteItem(item)" class="error--text">
                    mdi-delete
                </v-icon>
            </template>
            <template v-slot:no-data>
                <v-progress-circular
                        v-if="loading"
                        indeterminate
                        color="secondary"
                ></v-progress-circular>
                <v-icon
                        v-else
                        x-large
                        color="grey lighten-1"
                >
                    mdi-tray-remove
                </v-icon>

            </template>
        </v-data-table>
    </div>
</template>
<script>
    import {required} from 'vuelidate/lib/validators'
    import {validationMixin} from 'vuelidate'
    import * as moment from 'moment'

    export default {
        name: 'Templates',
        mixins: [validationMixin],
        data: () => ({
            imgdialog: false,
            selectedSrc: null,
            headers: [
                {
                    text: 'Cover',
                    value: 'img_url',
                    sortable: false,
                    align: 'start'
                },
                {text: 'Date', value: 'date'},
                {text: 'Download Count', value: 'download_count'},
                {text: 'Category', value: 'category'},
                {text: 'Status', value: 'status'},
                {text: 'Actions', value: 'actions', sortable: false},
            ],
            data: [],
            apiUrl: process.env.VUE_APP_API_URL,
            loading: true,
            dialog: false,
            dialogDelete: false,
            error: '',
            editedIndex: -1,
            editedItem: {
                name: '',
                img_url: null,
                category: '',
                date: '',
                logo_position: 'Bottom-Right',
                is_dp_visible: '',
                dp_position: 'Bottom-Left',
                //description: '',
                status: '',
                type: '',
                // package_type: '',
            },
            defaultItem: {
                name: '',
                img_url: null,
                category: '',
                date: '',
                logo_position: 'Bottom-Right',
                is_dp_visible: '',
                dp_position: 'Bottom-Left',
                //description: '',
                status: '',
                type: '',
                //package_type: '',
            },
            selectedImage: null,
        }),
        created() {
            this.getTemplates();
        },
        methods: {
            openImage(src) {
                this.selectedSrc = src
                this.imgdialog = true
            },
            editItem(item) {
                this.selectedImage = item.img_url
                this.editedIndex = this.data.indexOf(item);
                this.editedItem = Object.assign({}, item);
                this.editedItem.img_url = null
                this.dialog = true;
            },
            deleteItem(item) {
                this.editedIndex = this.data.indexOf(item);
                this.editedItem = Object.assign({}, item);
                this.dialogDelete = true;
            },
            deleteItemConfirm() {
                this.deleteTemplate(this.editedItem.id)
                this.closeDelete();
            },
            close() {
                this.selectedImage = null
                this.dialog = false;
                this.$v.$reset()
                this.error = ''
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                });
            },
            closeDelete() {
                this.dialogDelete = false;
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                });
            },
            save() {
                this.editedIndex == -1 ? this.addTemplate() : this.updateTemplate()
            },
            //Templates API Calls
            getImage() {
                let formData = new FormData();
                formData.append('img_url', this.editedItem.img_url);
                this.$Axios({
                    method: 'post',
                    url: 'add-template-image',
                    data: formData,
                    headers: {'Content-Type': 'multipart/form-data'},
                })
                    .then(({data}) => {
                        this.selectedImage = data.publicUrl
                    })
                    .catch((e) => {
                        console.log(e.response);
                        alert(e.response?.data?.msg ?? '');
                    });
            },
            getTemplates() {
                this.loading = true
                this.$Axios
                    .get('get-templates')
                    .then((res) => {
                        this.data = res.data["AllTemplates"];
                    })
                    .catch((e) => {
                        console.log(e);
                    })
                    .finally(() => {
                        this.loading = false
                    })
            },
            addTemplate() {

                this.error = ''
                this.$v.$touch()
                console.log(this.$v)
                if (this.$v.$error) {
                    return
                }
                let data = {}
                data.name = this.editedItem.name
                data.img_url = this.selectedImage
                data.category = this.editedItem.category
                data.date = this.editedItem.date
                data.logo_position = this.editedItem.logo_position
                // data.description = this.editedItem.description
                data.is_dp_visible = this.editedItem.is_dp_visible
                if (this.editedItem.is_dp_visible == 'Yes') data.dp_position = this.editedItem.dp_position
                data.status = this.editedItem.status
                data.type = this.editedItem.type
                //data.package_type = this.editedItem.package_type
                this.$Axios.post('template/add-template', data)
                    .then(() => {
                        this.getTemplates();
                        this.close();
                    })
                    .catch((e) => {
                        console.log(e)
                    });
            },
            updateTemplate() {
                this.error = ''
                /*this.$v.$touch()

                 if(this.$v.$error){
                    return
                }*/
                let data = {}
                if (this.editedItem.name) data.name = this.editedItem.name
                if (this.editedItem.img_url) data.img_url = this.selectedImage
                if (this.editedItem.category) data.category = this.editedItem.category
                if (this.editedItem.date) data.date = this.editedItem.date
                if (this.editedItem.logo_position) data.logo_position = this.editedItem.logo_position
                if (this.editedItem.dp_position) data.dp_position = this.editedItem.dp_position
                //if (this.editedItem.description) data.description = this.editedItem.description
                //if (this.editedItem.is_dp_visible) data.is_dp_visible = this.editedItem.is_dp_visible

                if (this.editedItem.is_dp_visible == 'No') {
                    data.dp_position = '';
                    data.is_dp_visible = this.editedItem.is_dp_visible
                } else {
                    data.dp_position = this.editedItem.dp_position;
                    data.is_dp_visible = this.editedItem.is_dp_visible
                }


                if (this.editedItem.status) data.status = this.editedItem.status
                if (this.editedItem.type) data.type = this.editedItem.type
                // if (this.editedItem.package_type) data.package_type = this.editedItem.package_type
                this.$Axios.post('template/edit-template?id=' + this.editedItem.id, data)
                    .then(() => {
                        this.getTemplates();
                        this.close();
                    })
                    .catch((e) => {
                        console.log(e)
                    });
            },
            deleteTemplate(id) {
                this.$Axios
                    .post('template/delete-template?id=' + id)
                    .then(() => {
                        this.getTemplates();
                    })
                    .catch((e) => {
                        console.log(e);
                    })
            },
        },
        computed: {
            formTitle() {
                return this.editedIndex === -1 ? 'New Template' : 'Edit Template';
            },
            formIcon() {
                return this.editedIndex === -1 ? 'mdi-plus' : 'mdi-edit';
            },
            formcrossIcon() {
                return this.editedIndex === -1 ? 'mdi-close' : 'mdi-close';
            },
            filtredItems() {
                if (this.filter) return this.desserts.filter(item => item.name.includes(this.filter) || item.license.includes(this.filter) || item.email.includes(this.filter) || item.db.includes(this.filter))
                else return this.desserts
            },
            //// Vuelidate Errors
            nameErrors() {
                const errors = []
                if (!this.$v.editedItem.name.$dirty) return errors
                !this.$v.editedItem.name.required && errors.push('Name is required !')
                return errors
            },
            img_urlErrors() {
                const errors = []
                if (!this.$v.editedItem.img_url.$dirty) return errors
                !this.$v.editedItem.img_url.required && errors.push('Cover is required !')
                return errors
            },
            categoryErrors() {
                const errors = []
                if (!this.$v.editedItem.category.$dirty) return errors
                !this.$v.editedItem.category.required && errors.push('Category is required')
                return errors
            },
            dateErrors() {
                const errors = []
                if (!this.$v.editedItem.date.$dirty) return errors
                !this.$v.editedItem.date.required && errors.push('Date is required')
                !this.$v.editedItem.date.date && errors.push('Date must be YYYY-MM-DD')
                return errors
            },
            logo_positionErrors() {
                const errors = []
                if (!this.$v.editedItem.logo_position.$dirty) return errors
                !this.$v.editedItem.logo_position.required && errors.push('Logo position is required')
                return errors
            },
            dp_positionErrors() {
                const errors = []
                if (!this.$v.editedItem.dp_position.$dirty) return errors
                !this.$v.editedItem.dp_position.required && errors.push('Position is required')
                return errors
            },
            statusErrors() {
                const errors = []
                if (!this.$v.editedItem.status.$dirty) return errors
                !this.$v.editedItem.status.required && errors.push('Status is required')
                return errors
            },
            typeErrors() {
                const errors = []
                if (!this.$v.editedItem.type.$dirty) return errors
                !this.$v.editedItem.type.required && errors.push('Type is required')
                return errors
            },
            // package_typeErrors () {
            //     const errors = []
            //     if (!this.$v.editedItem.package_type.$dirty) return errors
            //     !this.$v.editedItem.package_type.required && errors.push('Package type is required')
            //     return errors
            // },
        },
        watch: {
            dialog(val) {
                val || this.close();
            },
            dialogDelete(val) {
                val || this.closeDelete();
            },
        },
        validations: {
            editedItem: {
                name: {
                    required
                },
                img_url: {
                    required,
                },
                category: {
                    required
                },
                date: {
                    required,
                    date: (value) => {
                        return moment(value, 'YYYY-MM-DD', true).isValid()
                    }
                },
                logo_position: {
                    required
                },
                dp_position: {
                    required
                },
                is_dp_visible: {
                    required
                },
                status: {
                    required
                },
                type: {
                    required
                },
                // package_type: {
                //   required
                // }
            },
        },
    };
</script>